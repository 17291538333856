import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// MediData
import Home from "./views/Home";
import Tickets from "./views/Step20_Tickets";
import Shipping from "./views/Step30_Shipping";
import Checkout from "./views/Step40_Checkout";
import Confirmation from "./views/Step50_Confirmation";

// Fallback page
import PageNotFound from "./views/components/PageNotFound";

function App() {
    return (
        <BrowserRouter>
        <Routes>
        <Route path="/" element={<Navigate to="/de/home" replace />} />
        <Route path="*" element={<PageNotFound />} />

        <Route path="/:language/home" element={<Home />} />
        <Route path="/:language/tickets/:eventId/:timeSlotId" element={<Tickets />} />
        <Route path="/:language/shipping" element={<Shipping />} />
        <Route path="/:language/checkout" element={<Checkout />} />
        <Route path="/:language/confirmation/:orderId" element={<Confirmation />} />
        </Routes>
        </BrowserRouter>
);
}

export default App;
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getAddressOfOrder } from "ticketino-api-client";

import "../css/style.css";
import Header from "./components/Header";

const Step30_Shipping = () => {
    const [orderId] = useState(sessionStorage.getItem("orderId"));
    const [token] = useState(sessionStorage.getItem("token"));
    const [countries, setCountries] = useState([]);
    const [tickets, setTickets] = useState([]);

    const [user, setUser] = useState({
        salutation: "Herr",
        firstname: "",
        lastname: "",
        company: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        email: ""
    });
    const [event, setEvent] = useState({});

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        if (orderId) {
            loadOrder(orderId);
            loadCountries()
        }
    }, [orderId]); //gets called when an order is started

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            const bookedTickets = order.tickets;

            const address = await getAddressOfOrder(orderId);

            setUser({ ...user, firstname: address.firstName, lastname: address.name, email: address.email });
            setTickets(bookedTickets);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const addAddressToBasket = async () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            company: user.company,
            street: user.street,
            postCode: user.place,
            city: user.city,
            countryId: user.country,
            email: user.email,
            languageId: languageId
        };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => navigate(`/${language}/checkout`))
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const goBack = () => {
        let ticketsInOrder = [];

        tickets.map(ticket => ticketsInOrder.push(ticket.id));

        removeTicketFromBasket(ticketsInOrder);

        navigate(-1);
    }

    const removeTicketFromBasket = (ticketIds) => {
        let body = {
            "TicketsToRemove": ticketIds
        }

        axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, { headers: { Accept: "application/json" }, data: body }).then((res) => {
            loadOrder(orderId);
        }).catch((error) => {
            console.error(error.response.data);
        });
    }

    async function loadCountries() {
        let countries = await requestCountries();

        if (countries == null) navigate(`/${language}/home`);;

        //first add switzerland, germany and austria
        const indexCH = countries.findIndex((c) => c.id === 176);
        const ch = countries[indexCH];
        countries.splice(indexCH, 1);

        var indexDE = countries.findIndex((c) => c.id === 229);
        const de = countries[indexDE];
        countries.splice(indexDE, 1);

        var indexAU = countries.findIndex((c) => c.id === 228);
        const au = countries[indexAU];
        countries.splice(indexAU, 1);

        countries.unshift({ id: "", name: "---------" });
        countries.unshift(au);
        countries.unshift(de);
        countries.unshift(ch);

        setCountries(countries);
    }

    const requestCountries = async () => {
        var response = await axios.get(`${baseUrl}/Countries?languageCode=${language.slice(0, 2)}`);
        return response.data;
    };

    // validating input fields
    let validation =
        /^([a-z A-Z������]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.street) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^([a-z A-Z������ 0-9 - ]{1,70})$/.test(user.country) &&
        /^\S+@\S+\.\S+$/.test(user.email);

    return (
        <div className="container wrapper">
            <Header />
            <div className="mt-4">
                <div>
                    <div className="mb-3">
                        <p className="fs-6 text-container mb-3">{resources.BuyerInformation}</p>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerSalutation}</label>
                        <div className="col-sm-10">
                            <select
                                className="form-select"
                                name="salutation"
                                value={user.salutation}
                                onChange={(e) => onInputChange(e)}>
                                <option>{resources._Mr}</option>
                                <option>{resources._Mrs}</option>
                                <option>{resources._Divers}</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerFirstname}:</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="firstname"
                                value={user.firstname}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerLastname}:</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="lastname"
                                value={user.lastname}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerCompany}:</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="company"
                                value={user.company}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerStreet}:</label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="street"
                                value={user.street}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerPlzCity}:</label>
                        <div className="col-sm-5">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="place"
                                value={user.place}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                        <div className="col-sm-5">
                            <input
                                type="text"
                                className="form-control input-field"
                                name="city"
                                value={user.city}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label text-start address-field">{resources.BuyerCountry}:</label>
                        <div className="col-sm-10">
                            <select
                                className="form-select"
                                aria-label="Land"
                                name="country"
                                value={user.country}
                                onChange={(e) => onInputChange(e)}>
                                {countries.map((c, index) => (
                                    <option value={c.id} key={index}>
                                        {c.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label htmlFor="staticEmail" className="col-sm-2 col-form-label text-start address-field">
                            {resources.BuyerEmail}:
                        </label>
                        <div className="col-sm-10">
                            <input
                                type="text"
                                className="form-control input-field"
                                id="staticEmail"
                                name="email"
                                value={user.email}
                                onChange={(e) => onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mb-4">
                    <div className=" col-6 text-start">
                        <button className="button" onClick={() => goBack()}>
                            {resources.Back}
                        </button>
                    </div>
                    <div className="col-6 text-end">
                        <button className="button" disabled={!validation} onClick={() => addAddressToBasket()}>
                            {resources.Next}
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Step30_Shipping;
import logo from "../../images/logo.jpg";

const Header = (props) => {

    const { showText, resources } = props;

    return (
        <>
            <div className='row'>
                <div className='col-12 logo'>
                    <img className='img-fluid' src={logo} alt="logo image"></img>
                </div>
            </div>
            {(showText && resources) && <div>
                <p className="fs-4 fw-bold mb-2 mt-2">{resources.Title}</p>
                <p className="fs-5 fw-bold mb-2">{resources.SubTitle}</p>
                <p className="fs-6 mb-2">{resources.Description}</p>
                <p className="fs-6 mb-0 fw-bold">{resources.Address1}</p>
                <ul style={{ listStyle: "none", padding: 0 }}>
                    <li>{resources.Address2}</li>
                    <li>{resources.Address3}</li>
                    <li>{resources.Address4}</li>
                </ul>
            </div>}
        </>
    );
};

export default Header;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById } from "ticketino-api-client";
import { Calendar } from "react-calendar";

import Header from "./components/Header";
import "../css/style.css";
import "../css/calendar.css";

const Home = () => {
    const [date, setDate] = useState(new Date());
    const [events, setEvents] = useState([]);
    const [eventGroupId, setEventGroupId] = useState({});
    const [orderId, setOrderId] = useState();
    const [eventDates, setEventDates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedEventId, setSelectedEventId] = useState(0);
    const [showSidebar, setShowSidebar] = useState(false);

    const today = new Date();
    const defaultMonth = today.getMonth() < 9 ? 8 : 9; // Default month to November or December
    const [currentMonth, setCurrentMonth] = useState(new Date(2023, defaultMonth, 1));

    const { language } = useParams();

    let navigate = useNavigate();
    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {
        loadToken();
        requestResources();
    }, [language]);

    useEffect(() => {
        if (orderId) {
            loadEvents(eventGroupId, currentMonth);
        }
    }, [currentMonth]);

    const loadToken = async () => {
        try {
            const res = await axios.get("form/token");
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
            api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
            sessionStorage.setItem("token", res.data);
            requestFormSettings();
        } catch (error) {
            console.error(error);
        }
    };

    const requestFormSettings = async () => {
        try {
            const res = await axios.get(`form/formsettings`);
            startOrder(res.data.posId);
            setEventGroupId(res.data.eventGroupId);
        } catch (error) {
            console.error(error);
        }
    };

    const requestResources = async () => {
        try {
            const res = await axios.get(`form/resources/${language}`);
            setResources(res.data?.translation);
        } catch (error) {
            console.error(error);
        }
    };

    const startOrder = async (posId) => {
        const order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const res = await axios.post(`${baseUrl}/ShopBasket/Order`, order);
            sessionStorage.setItem("orderId", res.data.id);
            setOrderId(res.data.id);
            setCurrentMonth(new Date(2023, defaultMonth, 1));
        } catch (error) {
            console.error(error);
        }
    };

    const loadEvents = async (eventGroupId, currentMonth) => {
        setLoading(true);

        try {
            // EventGroup
            const eventsOfEventGroup = await getEventsByEventGroupId(eventGroupId);

            let datesArray = [];

            const updatedEvents = await Promise.all(
                eventsOfEventGroup.map(async (e) => {
                    const event = await getEventById(e.id);
                    const timeSlots = await requestTimeSlotsForEvent(e.id);
                    const count = await requestTicketCount(e.id);

                    const soldOut = (event?.maxTickets - count) <= 0;

                    if (!soldOut) {
                        datesArray.push(event.start?.split('T')[0]);
                    }

                    return { ...e, isSoldOut: soldOut, maxTickets: event?.maxTickets, ticketCount: count, timeSlots: timeSlots }
                })
            )

            setEventDates(datesArray);
            setEvents(updatedEvents);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error(error);
        }
    };

    const getEventsByEventGroupId = async (eventGroupId) => {
        try {
            const res = await axios.get(`${baseUrl}/EventGroup/Events?eventGroupId=${eventGroupId}&languageCode=${language != "de" || language != "fr" || language != "it" || language != "en" ? "de" : language}`);
            return res.data?.events;
        } catch (error) {
            console.error(error);
        }
    }

    const requestTicketCount = async (id) => {
        try {
            const res = await axios.get(`${baseUrl}/Event/${id}/TicketsSold`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    };

    const requestTimeSlotsForEvent = async (id) => {
        try {
            const res = await axios.get(`${baseUrl}/Event/${id}/TimeSlots`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const tileClassName = ({ date }) => {
        const dateString = new Date(date);
        dateString.setDate(dateString.getDate() + 1);
        const formattedDate = dateString.toISOString().split('T')[0];
        return eventDates.includes(formattedDate) ? 'bold-date' : 'disabled-date';
    };

    const handleActiveStartDateChange = ({ activeStartDate }) => {
        setCurrentMonth(activeStartDate);

        // clear old values
        setSelectedDate("");
        setSelectedEventId(0);
    };

    const onClickDay = (date) => {
        let selectedDate = new Date(date);
        selectedDate.setDate(selectedDate.getDate() + 1);

        const formattedDate = selectedDate.toISOString().split('T')[0];

        const event = events.find(e => e.start?.split('T')[0] == formattedDate);

        if (event?.id > 0) {
            setSelectedEventId(event.id);
        } else {
            setSelectedEventId(0);
        }

        setShowSidebar(true);
    };

    const onCheckedChange = (e) => setSelectedEventId(e.target.value);

    const mapEventStartTimes = () => {

        const currentEvent = events.find((event) => event.id == selectedEventId);
        const timeSlotsOfEvent = currentEvent?.timeSlots;

        return (
            (currentEvent?.timeSlots.length > 0) && timeSlotsOfEvent.map((ts, index) => (
                <div key={index}>
                    <div className={"time-slot text-center mb-3 mt-3" + (((ts.totalAvailableTickets - ts.totalTicketsSold) <= 0) ? " time-slot-disabled" : "")}>
                        <p className="mb-0 fw-bold">{ts.beginTime.substring(0, 5)} Uhr</p>
                        <p className="mb-2">{" "}
                            {((ts.totalAvailableTickets - ts.totalTicketsSold) == 1) ?
                                (ts.totalAvailableTickets - ts.totalTicketsSold + " " + resources.FreeTrain) :
                                ((ts.totalAvailableTickets - ts.totalTicketsSold) == 0) ? resources._SoldOut :
                                    (ts.totalAvailableTickets - ts.totalTicketsSold + " " + resources.FreeTrains)}</p>
                        <button className="next-button mb-3" onClick={() => navigate(`/${language}/tickets/${currentEvent.id}/${ts.timeSlotId}`)}>{resources.BuyTicketsButton}</button>
                    </div>
                    <hr className="side-hr"></hr>
                </div>
            ))
        );
    };

    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    return (
        <div>
            <>
                {resources && (
                    <div className="container wrapper">
                        <Header showText={true} resources={resources} />
                        <div className="calendar-container mb-2 mt-4 container-fluid">
                            <Calendar
                                view="month"
                                value={date}
                                onChange={setDate}
                                tileClassName={tileClassName}
                                onClickDay={onClickDay}
                                locale={language}
                                minDate={new Date(2023, defaultMonth, 1)}
                                maxDate={new Date(2023, 11, 31)}
                                onActiveStartDateChange={handleActiveStartDateChange}
                            />
                        </div>
                        {showSidebar && <div className="side-bar" id="side-bar">
                            <button className="close-btn" onClick={() => setShowSidebar(false)}><i className="bi bi-x-lg"></i></button>
                            <p className="fs-4 text-center">{resources.SelectTimeslot}</p>
                            <hr className="side-hr"></hr>
                            {selectedEventId > 0 && mapEventStartTimes()}
                        </div>}
                    </div>
                )}
            </>
        </div>
    );
};

export default Home;